.sd-input {
  @include useEditorFontSize;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: static;
  width: 100%;
  box-sizing: border-box;
  padding: calcSize(1.5) calcSize(2);
  line-height: multiply(1.5, $font-editorfont-size);
  font-family: $font-editorfont-family;
  font-weight: $font-editorfont-weight;
  color: $font-editorfont-color;
  font-size: $font-editorfont-size;
  background-color: $editor-background;
  border: none;
  border-radius: $editor-corner-radius;
  text-align: start;
  box-shadow: $shadow-inner, 0 0 0 0px $primary;
  transition: box-shadow $transition-duration;
}

.sd-input:focus {
  box-shadow: $shadow-inner-reset, 0 0 0 2px $primary;
}

.sd-input.sd-input:focus {
  outline: none;
}

//for IPHONE
input,
textarea {
  &.sd-input:disabled:not(.sd-input--disabled) {
    opacity: 1;
  }
}

.sd-input--disabled {
  background-color: $editor-background;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sd-input--readonly {
  background-color: $background-dark;
}

.sd-input.sd-input--preview {
  background: none;
  box-shadow: none;
  transition: none;
  border-bottom: 1px solid $foreground;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.sd-input::placeholder {
  color: $font-editorfont-placeholdercolor;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sd-input.sd-input--disabled,
.sd-input.sd-input--disabled::placeholder {
  color: $foreground;
  opacity: 0.25;
}

.sd-input.sd-input--readonly {
  color: $foreground;
  box-shadow: none;
  transition: none;
}

.sd-input.sd-input--readonly::placeholder {
  color: $foreground-light;
}

.sd-root--readonly .sd-input--disabled,
.sd-root--readonly .sd-input--disabled::placeholder {
  color: $foreground;
}

.sd-input--error {
  background-color: $red-light;
}

.sd-text__content {
  position: relative;
}

.sd-text__character-counter {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sd-text__character-counter:focus-within {
  padding-inline-end: calcSize(8);
}

.sd-text__character-counter.sd-text__character-counter--big:focus-within {
  padding-inline-end: calcSize(11);
}

.sd-remaining-character-counter {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  font-family: $font-family;
  line-height: calcLineHeight(1.5);
  font-size: calcFontSize(1);
  color: $foreground-light;
  position: absolute;
  inset-inline-end: calcSize(2);
  inset-block-end: calcSize(1.5);
}

.sd-text__content,
.sd-comment__content,
.sd-multipletext__content,
.sd-matrix__question-wrapper {
  &:focus-within .sd-remaining-character-counter {
    display: flex;
  }
}

@mixin runnable-track {
  background-color: $primary;
  border-radius: calcSize(1);
  height: calcSize(1.5);
}

@mixin slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: calcSize(-0.75);
  border-radius: 100%;
  border: 2px solid $primary;
  box-shadow: inset 0 0 0 2px var(--background, #fff);
  background-color: $primary;
  height: calcSize(3);
  width: calcSize(3);
}

.sd-input[type="time"],
.sd-input[type="date"],
.sd-input[type="datetime-local"],
.sd-input[type="week"],
.sd-input[type="month"],
.sd-input[type="tel"],
.sd-input[type="password"],
.sd-input[type="url"],
.sd-input[type="email"],
.sd-input[type="color"],
.sd-input[type="range"] {
  @include useEditorFontSize;
  box-sizing: content-box;
  width: calc(100% - 4 * #{$base-unit});
  height: multiply(1.5, $font-editorfont-size);

  &.sd-text__character-counter:focus-within {
    width: calc(100% - 10 * #{$base-unit});
  }
}

.sd-input[type="range"]::-webkit-slider-runnable-track {
  @include runnable-track;
}

.sd-input[type="range"]::-webkit-slider-thumb {
  @include slider-thumb;
}

//mozilla
.sd-input[type="range"]::-moz-range-track {
  @include runnable-track;
}

.sd-input[type="range"]::-moz-range-thumb {
  @include slider-thumb;
}